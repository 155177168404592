<template>
	<div class="content nav" :class="scrollFixed === true ? 'isFixed' : ''">
		<div class="top1 flexc">
			<div class=" leftp flexc">
				<p>
					Cross border cable smart trading platform</p>

			</div>
			<div class="right flexc">
				<!-- <p>
					<i class="el-icon-star-off"></i>
					WhatsApp：+8615036075455
				</p>
				<p>
					<i class="el-icon-user"></i>
					WhatsApp：+8615036075455
				</p> -->
				<p style="margin-right: 30px;">
					<i class="el-icon-message "></i>
					Email：yrccable@gmail.com
				</p>

				<p>
					<i class="el-icon-phone-outline"></i>
					WhatsApp：+8615036075455
				</p>
			</div>
		</div>
		<div class="bottom1 flexc">
			<div class="leftp">
				<router-link to="/"><img src="../assets/logo.png" alt=""></router-link>

			</div>
			<div class="right1 flexc">
				<el-menu class="el-menu-demo" mode="horizontal" text-color="#fff" background-color="#006CFF"
					active-text-color="#ffffff" :default-active="activeIndex" @select="handleSelect">
					<router-link to="/"> <el-menu-item index="/">Home</el-menu-item> </router-link>
					<el-submenu index="2">
						<template slot="title">About us</template>
						<router-link target="_blank" to="/aboutUs">
							<el-menu-item index="2-1">
								aboutUs
							</el-menu-item>
						</router-link>
						<router-link target="_blank" to="/figure">
							<el-menu-item index="2-2">figure</el-menu-item>
						</router-link>
						<router-link target="_blank" to="/certificate">
							<el-menu-item index="2-3">certificate
							</el-menu-item>
						</router-link>
					</el-submenu>
					<router-link target="_blank" to="/product">
						<el-menu-item index="3">product</el-menu-item>
					</router-link>
					<router-link target="_blank" to="/host">
						<el-menu-item index="4">News</el-menu-item>
					</router-link>
					<router-link target="_blank" to="/service">
						<el-menu-item index="5">Service</el-menu-item>
					</router-link>
					<el-submenu index="6">
						<template slot="title">Contact us</template>
						<router-link target="_blank" to="/contactUs">
							<el-menu-item index="6-1">contactUs</el-menu-item>
						</router-link>
						<router-link target="_blank" to="/foreig">
							<el-menu-item index="6-2">foreig</el-menu-item>
						</router-link>

					</el-submenu>
				</el-menu>
				<div class="serve" @click="isshow=!isshow" v-if="!isshow">
					<i slot="prefix" class="el-input__icon el-icon-search"></i>search
				</div>
				<el-input placeholder="Search" v-model="input4" v-if="isshow">
					<i slot="prefix" class="el-input__icon el-icon-search" @click="isshow=!isshow"></i>
				</el-input>
				<div class="user">
					<i class="el-icon-user-solid "></i> <span @click="$router.push('/login')">Username</span>
				</div>
			</div>
			<div class="pright">
				<div class="p-menu">
					<i class="el-icon-s-unfold" :class="{'picons': isok}" @click="change"></i>
					<div class="menu" v-if="isok">
						<el-menu class="el-menu-demo" mode="vertical" text-color="#fff" background-color="#006CFF"
							active-text-color="#fff" router :default-active="activeIndex" style="width: 200px;"
							@select="handleSelect">
							<el-menu-item index="/">Home</el-menu-item>
							<el-submenu index="2">
								<template slot="title">About us</template>
								<el-menu-item index="/aboutUs">aboutUs</el-menu-item>
								<el-menu-item index="/figure">figure</el-menu-item>
								<el-menu-item index="/certificate">certificate
								</el-menu-item>
							</el-submenu>
							<el-submenu index="3">
								<template slot="title">Product</template>
								<el-menu-item index="/product">product</el-menu-item>
								<!-- <el-menu-item index="/detailed">detailed</el-menu-item> -->
							</el-submenu>
							<el-menu-item index="/host">News</el-menu-item>
							<el-menu-item index="/service">Service</el-menu-item>
							<el-submenu index="6">
								<template slot="title">Contact us</template>
								<el-menu-item index="/contactUs">contactUs</el-menu-item>
								<el-menu-item index="/foreig">foreig</el-menu-item>
							</el-submenu>
							<el-menu-item index="/login">login</el-menu-item>
						</el-menu>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>
<script>
	import Cookies from "js-cookie";
	export default {
		data() {
			return {
				input4: '',
				scrollFixed: false,
				offsetTop: 0,
				activeIndex: '/',
				isok: false,
				isshow: false
			}
		},
		methods: {
			handleSelect(key) {
				this.activeIndex = key
				// 获取导航选中,存储vx防止跳转丢失
				this.$store.commit("change", key)
				// 存kookies 有效期7天
				Cookies.set('activeIndex', key, {
					expires: 7
				})
			},

			windowScroll() {
				// 滚动条顶部 距 滚动原点的高度
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				/**
				 * 三目运算
				 * 两个高度比较
				 * 如果滑动距离 > 吸顶元素到页面顶端距离  动态添加
				 */
				scrollTop > this.offsetTop ?
					(this.scrollFixed = true) :
					(this.scrollFixed = false);
			},
			change() {
				this.isok = !this.isok
			}
		},
		mounted() {
			// 需吸顶元素 距 离浏览器顶端的高度
			this.offsetTop = document.querySelector(".nav").offsetTop;
			// 滚动监听
			window.addEventListener("scroll", this.windowScroll);
		},
		created() {
			if (this.$store.state.activeIndex == undefined) {
				this.activeIndex = '/'
			} else {
				this.activeIndex = this.$store.state.activeIndex
			}
		},
		destroyed() {
			// 关闭 销毁监听
			window.removeEventListener("scroll", this.windowScroll);
		},
	}
</script>
<style lang="scss" scoped>
	.isFixed {
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		background: rgba(255, 255, 255, 1);
		opacity: 1;
		color: #000;
	}

	a {
		text-decoration: none;
	}

	::v-deep .el-icon-arrow-down {
		color: #fff !important;
	}

	::v-deep .el-menu-demo {
		display: flex;
	}

	.serve {
		color: #fff;
		cursor: pointer;
		margin: 0 50px 0 80px;
		display: flex;
		align-items: center;

		.el-input__icon {
			font-size: 25px;
			margin-right: 5px;

		}
	}

	.content {
		width: 100%;
		height: 100px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99999;

		.top1 {
			height: 36px;
			justify-content: space-between;
			padding: 0 200px;
			background-color: #fff;

			.leftp {}

			p {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #006CFF;
			}
		}

		.bottom1 {
			width: 100%;
			height: 64px;
			background: #006CFF;
			justify-content: space-between;
			padding: 0 200px;

			.leftp {
				// margin-right: 900px;
				color: #fff;
			}



			.right1 {
				.el-menu-demo {
					border: none;
					background-color: rgba(0, 0, 0, 0);
				}



				.el-input {
					margin-left: 30px;
					width: 150px;
					border-radius: 50px;
				}

				.user {
					display: flex;
					align-items: center;
					color: #fff;
					margin-left: 20px;

					.el-icon-user-solid {
						font-size: 25px;
					}

					span {
						display: inline-block;
						margin-left: 5px;
						cursor: pointer;
					}

					span:hover {
						color: #000000;
					}
				}
			}

			.pright {
				display: none;

				i {
					transition: all 1s;
				}

			}

		}
	}

	@media only screen and (max-width: 1680px) {
		.content {
			.top1 {
				padding: 0 150px;
			}

			.bottom1 {
				padding: 0 150px;
			}
		}

	}

	@media only screen and (max-width: 1440px) {
		.content {
			.top1 {
				padding: 0 100px;
			}

			.bottom1 {
				padding: 0 100px;
			}
		}

	}

	@media only screen and (max-width: 1280px) {
		.content {
			.top1 {
				padding: 0 10px;
			}

			.bottom1 {
				padding: 0 10px;
			}

			.el-input {
				display: none;
			}
		}

	}


	@media only screen and (max-width: 1024px) {

		::v-deep .el-menu-demo {
			display: flex;
			flex-direction: column;
		}

		.content {
			height: 64px !important;

			.picons {
				// transform: rotate(90deg);
			}



			.top1 {
				display: none;
			}

			.bottom1 {
				padding: 0 30px;

				.right1 {
					display: none;
				}

				.pright {
					display: block;


					.p-menu {
						position: relative;

						.el-icon-s-unfold {
							font-size: 33px;
							color: #fff;
						}

						.menu {
							position: absolute;
							top: 48px;
							left: -136px;
							z-index: 999999999999999;
						}
					}
				}
			}
		}
	}
</style>