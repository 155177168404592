import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/host',
		name: 'host',
		component: () => import('../views/Host.vue')
	},
	{
		path: '/service',
		name: 'service',
		component: () => import('../views/Service.vue')
	},
	{
		path: '/contactUs',
		name: 'contactUs',
		component: () => import('../views/ContactUs.vue')
	},
	{
		path: '/product',
		name: 'product',
		component: () => import('../views/Product.vue')
	},
	{
		path: '/figure',
		name: 'figure',
		component: () => import('../views/about/Figure.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/contact/Login.vue')
	},
	{
		path: '/foreig',
		name: 'foreig',
		component: () => import('../views/contact/Foreig.vue')
	},
	{
		path: '/aboutUs',
		name: 'aboutUs',
		component: () => import('../views/about/AboutUs.vue')
	},
	{
		path: '/certificate',
		name: 'certificate',
		component: () => import('../views/about/Certificate.vue')
	},
	{
		path: '/detailed',
		name: 'detailed',
		component: () => import('../views/product/Detailed.vue')
	},
	{
		path: '/new',
		name: 'new',
		component: () => import('../views/product/HostNew.vue')
	}
]
const router = new VueRouter({
	base: '/',
	mode: 'history',
	routes
})

// 避免了到当前位置的冗余导航
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {

	return originalPush.call(this, location).catch(err => err)

}
export default router