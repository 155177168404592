<template>
	<div class="container">
		<Nav></Nav>
		<!-- <swiper :num='1' :pics='isok?pics:picsp'></swiper> -->
		<swiper :num='1' :pics='pics' class="swiper" />
		<phone :pics='picsp' class="pswiper"></phone>
		<div class="one">
			<el-row :gutter="50">
				<el-col :xs="24" :sm="24" :md="12" :lg="12">
					<div class="left">
						<img src="../assets/banner-min1.webp" alt="" />
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12">
					<div class="right">
						<div class="title">
							Yellow River Cloud Cable
						</div>
						<div class="p1">
							The Yellow River Cloud Cable driven by blockchain technology, supported by product
							traceability system and visual process, it focuses on cable product quality and industrial
							upgrading, and provides domestic and foreign users with a credible and efficient one-stop
							smart transaction solution for the cable industry.
						</div>
						<div class="p2">
							The Yellow River Cloud Cable as a cross-border smart trading platform focusing on the cable
							industry, it integrates R&D, production, sales, solutions, and construction. Has more than
							ten directly-operated factories and production bases, and has a complete testing and
							inspection system, a complete quality control system, and the whole process. The Internet of
							Things traceability system and perfect customer service system, etc.
						</div>
						<div class="bottom-btn">
							<div class="btn">
								About us
							</div>
							<div class="btn">
								Factory display
							</div>
							<div class="btn" style="margin-right:0 ;">
								Contact us
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="box">
			<div class="title">Our Product</div>
			<div class="box-cons">
				<el-row :gutter="20">
					<el-col :span="6" :xs="12" :sm="12" :md="6" :lg="6" v-for="item in shoplist" :key="item.productId">
						<div class="box-item" @click="goShop(item.productId)">
							<img :src="item.productCoverImg" alt="">
							<p>
								{{item.productName}}
							</p>
							<div class="bottom">
								<div class="btn">
									{{item.productModel}}
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>

			<div class="btn1">
				More +
			</div>
		</div>
		<div class="two">
			<div class="title">
				Project Process
			</div>
			<div class="content">
				<el-row :gutter="20">
					<el-col :span="6" :xs="12" :sm="12" :md="6" :lg="6">
						<div class="two-item">
							<img src="../assets/icon0.png" alt="">
							<p>Obtain quotation</p>
							<div class="text">
								To get an accurate quote, you must describe the order quantity, special requirements in
								the inquiry, and then our team will provide you with an accurate quote
							</div>
						</div>
					</el-col>
					<el-col :span="6" :xs="12" :sm="12" :md="6" :lg="6">
						<div class="two-item">
							<img src="../assets/icon2.png" alt="">
							<p>Confirm Puchasing Order</p>
							<div class="text">
								After the order is confirmed, we will provide the production report. The engineer will
								point out any problems that may affect the quality of the product.
							</div>
						</div>
					</el-col>
					<el-col :span="6" :xs="12" :sm="12" :md="6" :lg="6">
						<div class="two-item">
							<img src="../assets/icon3.png" alt="">
							<p>Production manufacturing</p>
							<div class="text">
								According to customer needs and the actual situation of the project, provide intelligent
								automation scheme design, can check the production situation through the traceability
								system.
							</div>
						</div>
					</el-col>
					<el-col :span="6" :xs="12" :sm="12" :md="6" :lg="6">
						<div class="two-item">
							<img src="../assets/icon4.png" alt="">
							<p>Delivery order</p>
							<div class="text">
								After the product inspection is qualified, we will ship it as soon as possible,
								and we will try our best to deliver your package to you as soon as possible
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="three">
			<div class="title">
				Specialized Chemical Factory
			</div>
			<div class="content">
				<el-row :gutter="20">
					<el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12">
						<div class="three-item1">
							<div class="img">
								<img src="../assets/gongchang1.webp" alt="">

							</div>
							<div class="cover1">
								<p>Production Shop</p>
								<div class="btn">
									Learn More>>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="12" :sm="12" :md="6" :lg="6">
						<div class="three-item">
							<div>
								<img src="../assets/gongchang2.png" alt="">
								<div class="cover">
									Inspection room
								</div>
							</div>
							<div class="d1">
								<img src="../assets/gongchang4.png" alt="">
								<div class="cover">
									laboratory
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="12" :sm="12" :md="6" :lg="6">
						<div class="three-item">
							<div>
								<img src="../assets/gongchang3.png" alt="">
								<div class="cover">
									Production shop
								</div>
							</div>
							<div class="d1">
								<img src="../assets/gongchang5.png" alt="">
								<div class="cover">
									Experimental building
								</div>
							</div>

						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="four">
			<div class="title">
				Customer Case
			</div>
			<div class="text">
				At present, our cable is not only used in the domestic, but also in Germany, Denmark, Brazil,
				Hungary,<br>
				Turkey, Estonia, India, Thailand, Iraq and other overseas projects.
			</div>
			<div class="content">
				<div class="four-item">
					<img src="../assets/anli1.png" alt="">
					<p>Pakistan</p>
				</div>
				<div class="four-item">
					<img src="../assets/anli2.png" alt="">
					<p>Nigeria</p>
				</div>
				<div class="four-item">
					<img src="../assets/anli3.png" alt="">
					<p>South Africa</p>
				</div>
				<div class="four-item">
					<img src="../assets/anli4.png" alt="">
					<p>Turkish</p>
				</div>
				<div class="four-item" style="margin-right:0 ;">
					<img src="../assets/anli5.png" alt="">
					<p>Pakistan</p>
				</div>

			</div>
		</div>
		<div class="five">
			<div class="title">
				News Center
			</div>
			<div class="content" v-if="newlist!=''">
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="12" :lg="12">
						<div class="five-item" @click="goNew(newlist[0].newsId)">
							<img :src="newlist[0].newsCoverImg" alt="">
							<div class="box1">
								<div class="top">
									{{newlist[0].newsTitle}}
								</div>
								<div class="time">
									{{newlist[0].createTime}}
								</div>
								<div class="text">
									{{newlist[0].newsSubTitle}}
								</div>
								<p>Read More>></p>
							</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12">
						<div class="five-item" v-for="item in bnewlist" :key="item.newsId">
							<div class="box1" @click="goNew(item.newsId)">
								<div class="top">
									{{item.newsTitle}}
								</div>
								<div class="time">
									{{item.createTime}}
								</div>
								<div class="text">
									{{item.newsSubTitle}}
								</div>
								<p>Read More>></p>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="six">
			<p>Welcome To Consult Us!</p>
			<p>Our Slogan: Make Cable With Real "core"</p>
			<div class="btn">Obtain Quotation</div>
		</div>
		<bottom></bottom>
	</div>
</template>

<script>
	import Nav from '@/components/Nav.vue'
	import swiper from '@/components/swiper.vue'
	import phone from '@/components/sphone.vue'
	import bottom from '@/components/bottom.vue'
	export default {
		components: {
			Nav,
			swiper,
			bottom,
			phone
		},
		data() {
			return {
				pics: [{
						img: require("../assets/banner01.webp"),
					},
					{
						img: require("../assets/banner02.webp"),
					},
					{
						img: require("../assets/banner03.webp"),
					},
				],
				picsp: [{
						img: require("../assets/01.webp"),
					},
					{
						img: require("../assets/02.webp"),
					},
					{
						img: require("../assets/03.webp"),
					},
				],
				isok: true,
				windowWidth: '',
				shoplist: [],
				newlist: [],
				bnewlist: []
			}
		},
		created() {
			this.getNew()
			this.getShop()
		},
		methods: {
			// 获取新闻列表
			getNew() {
				this.$http.get('https://armoured.yrccable.com/prod-api/api/news/queryNewsList', {
						params: {
							newsIndexShow: 1,
							pageSize: 4
						}
					})
					.then(res => {
						if (res.data.code == 200) {
							this.newlist = res.data.rows
							this.bnewlist = res.data.rows.slice(1, res.data.rows.length)
						}
					}).catch(err => {
						console.log(err);
					})
			},
			// 去新闻详情
			goNew(e) {
				this.$router.push({
					path: '/new',
					query: {
						id: e
					}
				})
			},
			async getShop() {
				const {
					data
				} = await this.$http.get('https://armoured.yrccable.com/prod-api/api/product/queryProductList')
				this.shoplist = data.rows
			},
			goShop(e) {
				this.$router.push({
					path: '/detailed',
					query: {
						id: e
					}
				})
			}
		},
		mounted() {
			var that = this
			window.onresize = () => {
				return (() => {
					window.fullHeight = document.documentElement.clientHeight;
					window.fullWidth = document.documentElement.clientWidth;
					that.windowHeight = window.fullHeight; // 高
					that.windowWidth = window.fullWidth; // 宽
					if (that.windowWidth > 1000) {
						that.isok = true
					} else {
						that.isok = false
					}
				})()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		.pswiper {
			display: none;
		}

		.swiper {
			padding-top: 0px;
		}

		.one {
			width: 100%;
			// height: 300px;
			padding: 20px 0;
			display: flex;
			justify-content: center;

			.left {
				width: 600px;
				height: 400px;

				img {
					width: 100%;
				}
			}

			.right {
				width: 636px;

				.title {
					font-size: 32px;
					font-weight: bold;
					color: #006CFF;
					margin: 21px 0 29px 0;
				}

				p {

					height: 88px;
					font-size: 16px;
					font-weight: 400;
					color: #666666;
					line-height: 24px;
				}

				.p2 {
					margin: 17px 0 21px 0;
				}

				.bottom-btn {
					display: flex;
					// justify-content: space-around;

					.btn {
						border: 1px solid #006CFF;
						border-radius: 2px;
						padding: 10px;
						font-size: 14px;
						font-weight: 400;
						color: #006CFF;
						margin-right: 30px;
					}
				}
			}
		}

		.box {
			background: #F1F3F5;
			padding: 40px 0;

			.title {
				font-size: 32px;
				font-family: PingFang SC;
				font-weight: 800;
				color: #333333;
				text-align: center;
				margin-bottom: 26px;
			}

			.box-cons {
				width: 1280px;
				margin: 0 auto 50px;
				// margin-bottom: 50px;
				display: flex;
				justify-content: center;
			}

			.box-item {
				width: 300px;
				// height: 410px;
				background: rgba(0, 108, 255, 0.05);
				border-radius: 2px;
				transition: all 1s;
				cursor: pointer;
				padding-bottom: 10px;
				margin-bottom: 30px;

				img {
					width: 300px;
					height: 300px;
				}

				p {
					margin: auto;
					width: 263px;
					font-size: 20px;
					font-weight: bold;
					color: #333333;
					line-height: 24px;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 2;
					display: -webkit-box;
					-webkit-box-orient: vertical;
				}

				.bottom {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-left: 13px;
					margin-top: 14px;

					.btn {
						padding: 5px 10px;
						border: 1px solid #999999;
						border-radius: 2px;
						font-size: 14px;
						font-weight: 400;
						color: #999999;
						text-align: center;
						margin-right: 30px;
					}
				}
			}

			.box-item:hover {
				transform: translateY(-5px);
				box-shadow: 2px 5px 5px #ccc;
			}

			.btn1 {
				width: 164px;
				height: 44px;
				border: 1px solid #006CFF;
				border-radius: 2px;
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #006CFF;
				text-align: center;
				line-height: 44px;
				margin: auto;
				cursor: pointer;
				transition: all 1s;
			}

			.btn1:hover {
				box-shadow: 2px 2px 5px #ccc;
				transform: translateY(-5px);
			}
		}

		.two {
			margin: auto;
			width: 100%;
			height: 600px;
			background-image: url(../assets/bj1.webp);
			background-repeat: no-repeat;
			background-size: 100% 600px;

			.title {
				padding: 50px 0;
				text-align: center;
				font-size: 32px;
				font-weight: 800;
				color: #FFFFFF;
			}

			.content {
				display: flex;
				justify-content: center;

				.two-item {
					min-height: 360px;
					width: 300px;
					background: #FFFFFF;
					border-radius: 2px;
					padding: 30px 10px;
					display: flex;
					flex-direction: column;
					align-items: center;
					transition: all 1s;
					cursor: pointer;

					img {
						width: 88px;
						height: 88px;
					}

					p {
						font-size: 20px;
						font-weight: 500;
						color: #006CFF;
						margin: 20px 0 18px 0;
					}

					.text {
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #666666;
						line-height: 24px;
						// width: 90%;
						// overflow: hidden;
						// text-overflow: ellipsis;
						// /* 将对象作为弹性伸缩盒子模型显示 */
						// display: -webkit-box;
						// /* 限制在一个块元素显示的文本的行数 */
						// /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
						// -webkit-line-clamp: 3;
						// /* 设置或检索伸缩盒对象的子元素的排列方式 */
						// -webkit-box-orient: vertical;
					}
				}

				.two-item:hover {
					transform: translateY(-5px);
					box-shadow: 2px 5px 0px #f5f5f5;
				}
			}
		}

		.three {
			.title {
				font-size: 32px;
				font-weight: 800;
				color: #333333;
				padding: 34px 0;
				text-align: center;
			}

			.content {
				display: flex;
				justify-content: center;

				.three-item1 {
					width: 700px;
					height: 560px;
					overflow: hidden;
					position: relative;


					.cover1 {
						position: absolute;
						bottom: 560px;
						left: 0;
						width: 700px;
						height: 560px;
						background: #000000;
						opacity: 0.6;
						border-radius: 0px 0px 2px 2px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						transition: all 2s;

						p {
							font-size: 30px;
							font-weight: bold;
							color: #FFFFFF;
							margin-bottom: 30px;
						}

						.btn {
							width: 160px;
							height: 50px;
							background: #006FFC;
							border-radius: 2px;
							font-size: 18px;
							font-weight: 500;
							color: #FFFFFF;
							text-align: center;
							line-height: 50px;
						}
					}

					img {
						width: 100%;
					}
				}

				.three-item1:hover .cover1 {
					transform: translateY(560px);
				}

				.three-item {
					width: 350px;

					div {
						position: relative;
					}

					.cover {
						position: absolute;
						bottom: 5px;
						left: 0;
						width: 350px;
						height: 50px;
						background: #000000;
						opacity: 0.6;
						font-size: 24px;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 50px;
						padding-left: 20px;
					}

					.d1 {
						margin-top: 13px;
					}

					img {
						width: 100%;
					}
				}
			}
		}

		.four {
			width: 100%;
			padding-bottom: 30px;

			.title {
				font-size: 32px;
				font-weight: 800;
				color: #333333;
				text-align: center;
				padding: 60px 0 30px 0;
			}

			.text {
				width: 727px;
				height: 39px;
				font-size: 14px;
				font-weight: 400;
				color: #666666;
				margin: auto;
				line-height: 30px;
				text-align: center;
			}

			.content {
				display: flex;
				justify-content: center;
				margin-top: 50px;

				.four-item {
					width: 330px;
					// height: 310px;
					border: 1px solid #E2E2E2;
					margin-right: 20px;
					transition: all 1s;
					cursor: pointer;

					img {
						width: 100%;
					}

					p {
						font-size: 24px;
						font-weight: 500;
						color: #333333;
						text-align: center;
						margin: 10px 0;
					}
				}

				.four-item:hover {
					transform: translateY(-5px);
					box-shadow: 2px 5px 5px #d6d6d6;
				}
			}
		}

		.five {
			width: 100%;


			.title {
				padding: 60px 0 50px 0;
				font-size: 32px;
				font-weight: 800;
				color: #333333;
				text-align: center
			}

			.content {
				display: flex;
				justify-content: center;
			}

			.five-item {
				width: 590px;
				// padding: 0 15px;
				background-color: #F1F3F5;
				cursor: pointer;

				img {
					width: 100%;
					height: 370px;
				}

				.box1 {
					width: 590px;
					padding: 10px;

					.top {
						width: 570px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						-o-text-overflow: ellipsis;
						font-size: 24px;
						font-weight: 500;
						color: #333333;
						margin: 10px 0;
					}

					.time,
					.text {
						font-size: 16px;
						font-weight: 400;
						color: #666666;
						line-height: 24px;
					}

					.text {
						width: 550px;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 2;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						margin: 10px 0;
					}

					p {
						font-size: 16px;
						font-weight: 400;
						color: #006CFF;
					}
				}
			}
		}

		.six {
			width: 100%;
			background-image: url(../assets/bj2.webp);
			background-repeat: no-repeat;
			background-size: auto;
			height: 238px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 50px;

			p {
				font-size: 32px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #FFFFFF;
			}

			.btn {
				width: 200px;
				height: 44px;
				background: #FFB400;
				border-radius: 2px;
				font-size: 16px;
				font-weight: 500;
				color: #FFFFFF;
				text-align: center;
				line-height: 44px;
				margin-top: 43px;
				transition: all 1s;
				cursor: pointer;
			}

			.btn:hover {
				box-shadow: 2px 2px 5px #ececec;
				transform: translateY(-5px);
			}
		}

		@media only screen and (max-width: 1680px) {
			.three {
				.three-item1 {
					width: 600px !important;
					height: 480px !important;

					.cover1 {
						width: 600px !important;
						height: 480px !important;
					}

					img {
						width: 600px;
						// height: 500px;
					}
				}

				.three-item1:hover .cover1 {
					transform: translateY(-500px);
				}

				.three-item {
					width: 300px !important;

					.cover {
						width: 300px !important;
					}
				}
			}

			.four {
				.four-item {}
			}
		}

		@media only screen and (max-width: 1440px) {
			.one {
				.left {
					width: 550px;
				}

				.right {
					width: 538px;
				}
			}

			.box {
				.box-item {
					width: 280px;

					img {
						width: 280px;
						height: 280px;
					}
				}
			}

			.two {
				.two-item {
					width: 280px !important;
					min-height: 400px;
				}
			}
		}




	}

	@media only screen and (max-width: 1280px) {
		.container {
			.one {
				.left {
					width: 500px;
				}

				.right {
					width: 500px;
				}
			}

			.box {
				.box-cons {
					width: 1000px;
				}

				.box-item {
					width: 250px;

					img {
						width: 250px;
						height: 250px;
					}
				}
			}

			.two {
				.two-item {
					width: 250px !important;
					// height: 400px;

					p {
						text-align: center;

					}
				}
			}

			.three {
				.three-item1 {
					width: 470px !important;
					height: 400px !important;
					margin: auto;

					.cover1 {
						width: 470px !important;
						height: 400px !important;
					}
				}

				.three-item {
					margin: auto;
					width: 235px !important;
					margin-top: 10px;

					.cover {
						width: 235px !important;
						height: 30px !important;
						line-height: 30px !important;
						font-size: 16px !important;
						text-align: center;
					}
				}
			}

			.five {
				.five-item {
					width: 500px;
					margin: auto;
					margin-bottom: 20px;

					img {
						width: 100%;
						height: 378px;
					}

					.box1 {
						width: 500px;

						.top {
							font-size: 14px;
						}

						.text {
							width: 406px;
						}
					}
				}
			}
		}

	}

	@media only screen and (max-width: 1024px) {
		.container {
			.pswiper {
				display: block;
			}

			.swiper {
				display: none;
			}

			.title {
				font-size: 24px !important;
				text-align: center;
			}

			.one {
				.left {
					// display: flex;
					// justify-content: center;
					margin: auto;
					width: 370px;
					height: auto;

					img {
						max-width: 370px;
						height: 300px;
					}
				}

				.right {

					margin: auto;
					width: 370px;
					height: auto;

					p {
						height: auto;
						text-indent: 2em;
					}
				}
			}

			.box {
				min-width: 370px;

				.box-cons {
					width: 350px;
				}

				.box-item {
					margin: auto;
					width: 175px;
					min-height: 313px;
					margin-top: 20px;


					img {
						width: 175px;
						height: 175px;
					}

					p {
						width: 150px;
						font-size: 16px;
					}

					.bottom {
						flex-wrap: wrap;
					}
				}
			}

			.two {
				background-image: none;
				background-color: #39320c;
				height: auto;

				.content {
					min-width: 370px;


					.two-item {
						margin: auto;
						width: 175px !important;
						margin-bottom: 20px;
						height: 380px;

						img {
							width: 44px;
							height: 44px;
						}

						p {
							font-size: 14px;
							text-align: center;
						}

						.text {
							font-size: 12px;
							text-indent: 1em;
						}
					}
				}
			}

			.three {
				.three-item1 {
					width: 370px !important;
					height: 300px !important;
					margin: auto;

					.cover1 {
						width: 370px !important;
						height: 300px !important;
					}
				}

				.three-item {
					margin: auto;
					width: 175px !important;
					margin-top: 30px;

					.cover {
						width: 175px !important;
						height: 30px !important;
						line-height: 30px !important;
						font-size: 16px !important;
						text-align: center;
					}
				}
			}

			.four {
				.text {
					width: 370px !important;
				}

				.content {
					flex-wrap: wrap;

					.four-item {
						margin: 10px 0;
					}
				}
			}

			.five {
				.five-item {
					width: 370px;
					margin: auto;
					margin-bottom: 20px;

					img {
						width: 100%;
						height: 270px;
					}

					.box1 {
						width: 360px;

						.top {
							font-size: 14px;
						}

						.text {
							width: 306px;
						}
					}
				}
			}

			.six {
				// background-image: none;
				background-color: #789fd6;

				p {
					font-size: 24px;
					text-align: center;
				}
			}
		}

	}
</style>