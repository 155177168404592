import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from "js-cookie";
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// 在cookies取导航标识
		activeIndex: Cookies.get('activeIndex')
	},
	getters: {},
	mutations: {
		// 切换导航方法
		change(state, payload) {
			state.activeIndex = payload
		}
	},
	actions: {},
	modules: {}
})