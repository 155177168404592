<template ref="page">
	<div class="banner bannerpc">
		<el-carousel :interval="5000" arrow="always" :height="bannerheight + 'px'" indicator-position>
			<el-carousel-item v-for="(item, index) in pics" :key="index">
				<img :src="item.img" alt="" ref="bannerheight" @load="imgload" style="object-fit: cover;" />
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	export default {
		props: {
			num: {
				type: Number,
				// default: 1
			},
			pics: {
				type: Array,
			}
		},
		data() {
			return {
				bannerheight: "",
				clientHeight: '',
				handleResize: null
			}
		},

		methods: {
			// 获取轮播高度
			imgload() {
				this.$nextTick(() => {
					// 获取图片高度
					// this.bannerheight = this.$refs.bannerheight[0].height;
					// 浏览器视口高度
					this.bannerheight = this.clientHeight / this.num;
				});
			},
			changeFixed(clientHeight) {
				if (this.$refs.page) {
					this.$refs.page.style.minHeight = clientHeight - 100 + 'px';
				}

				this.bannerheight = this.clientHeight / this.num;
			}
		},
		watch: {
			clientHeight() {
				//如果clientHeight 发生改变，这个函数就会运行
				this.changeFixed(this.clientHeight)
			}
		},
		mounted() {
			// 轮播自适应
			this.clientHeight = `${document.documentElement.clientHeight}`; //获取浏览器可视区域高度
			this.handleResize = window.onresize = () => {
				this.clientHeight = `${document.documentElement.clientHeight}`;
				if (this.$refs.page) {
					this.$refs.page.style.minHeight = clientHeight - 100 + 'px';
				}
			}
			window.addEventListener("resize", this.handleResize);
		},
		beforeDestroy() {
			// 消除轮播
			// window.removeEventListener("resize", this.imgload, false);
			window.removeEventListener("resize", this.handleResize);

		}
	}
</script>

<style lang="scss" scoped>
	.banner {
		padding-top: 100px;

		// display: flex;
		// justify-content: center;
		img {
			width: 100%;
			height: 100%;
		}
	}
</style>